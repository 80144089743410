header{position:fixed;top:0;left:0;width:100%;padding:15px 70px;z-index: 9999;background: #ffffff;-webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;}
.HeadContainer{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.headlogo{display: block;}
.headlogo a{display: block;}
.headlogo a:focus-visible{outline: unset;}
.headlogo a img{height: 50px;-webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;width: auto;}
nav.nav{margin-left: auto;}
.mobNav{display: none;}
ul.BSmainMenu{margin: 0;padding: 0;list-style: none;display: -webkit-box;display: -ms-flexbox;display: flex;height: 50px;}
ul.BSmainMenu li{margin-left: 45px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;position: relative;}
ul.BSmainMenu li a{color: #303030;text-transform: capitalize;font-weight: 500;text-decoration: none;}
ul.BSmainMenu li a:hover{color: #eb9500}
ul.BSmainMenu li a.active{color: #eb9500}
ul.BSmainMenu li span{color: #303030;text-transform: capitalize;font-weight: 500;text-decoration: none;cursor: pointer;position: relative;padding-right: 20px;}
ul.BSmainMenu li span:before{content: '';position: absolute;border-left: 5px solid transparent;border-right: 5px solid transparent;border-top: 7px solid #303030;right: 0px;top: 50%;margin-top: -3px;opacity: 1;}
ul.BSmainMenu li span:hover{color: #eb9500}
ul.BSmainMenu li.active span{color: #eb9500}
ul.BSmainMenu ul{ margin: 0; padding: 0; list-style: none; position: absolute; top: 200%; left: 0; width: 130px; transition:all 0.5s ease-in-out ; -webkit-transition:all 0.5s ease-in-out ; -moz-transition:all 0.5s ease-in-out ; -ms-transition:all 0.5s ease-in-out ; -o-transition:all 0.5s ease-in-out ;opacity: 0;pointer-events: none;}
ul.BSmainMenu li.haschild:hover ul{top: 100%;opacity: 1;pointer-events: all;}
ul.BSmainMenu ul::before{content: '';position: absolute;border-right: 8px solid transparent;border-left: 8px solid transparent;border-bottom: 6px solid #df8e03;top: -6px;left: 20px;}
ul.BSmainMenu ul li{margin: 0;}
ul.BSmainMenu ul li a{display: block;width: 100%;line-height: 50px;padding-inline: 12px;border-bottom: 1px solid rgb(255 255 255 / 20%);background: #df8e03;color: #ffffff;}
ul.BSmainMenu ul li:last-child a{border-bottom: 0px solid rgb(255 255 255 / 20%);}
ul.BSmainMenu ul li a:hover{color: #ffffff;background: #303030;}
ul.BSmainMenu ul li a.active{color: #ffffff;background: #303030;}

.scroll-up header {-webkit-transform: translate3d(0, 0%, 0);-ms-transform: translate3d(0, 0%, 0);transform: translate3d(0, 0%, 0);}
.scroll-down header {-webkit-transform: translate3d(0, 0%, 0);transform: translate3d(0, 0%, 0);}
.sscroll-animi header {background:#ffffff;box-shadow: 0 0 10px 1px rgb(0 0 0 / 7%);padding: 5px 70px;}
.sscroll-animi .headlogo a img {height: 30px;}
#HamburgerMegamenu{display: none;}


.scroll-down.sscroll-animi header {
    transform: translate3d(0, -100%, 0);
}


@media screen and (max-width:1700px) {
    header{padding: 25px 70px;}
    .headlogo a img {height: 46px;}
}
@media screen and (max-width:1500px) {
    header {padding: 20px 55px;}
    .sscroll-animi header{padding: 10px 55px;}
    .headlogo a img {height: 38px;}
    .sscroll-animi .headlogo a img {height: 25px;}
    ul.BSmainMenu{height: 40px;}
    ul.BSmainMenu li{margin-left: 30px;}
}
@media screen and (max-width:1100px) {
    header {padding: 12px 30px;}
    .headlogo a img {height: 32px;}
    ul.BSmainMenu {height: 32px;}
    ul.BSmainMenu li {margin-left: 20px;}
    .sscroll-animi header {padding: 10px 25px;}
    .sscroll-animi .headlogo a img {height: 22px;}
}
@media screen and (max-width:800px) {
    nav.nav{display: none;}
    .headlogo a img {height: 38px;}
    header {padding:0px;background: #ffffff;}
    .sscroll-animi header {padding: 0;}
    .headlogo {padding: 7px 20px;}
    .HeadContainer{/*-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;*/}
    .mobNav{display: block;margin-left: auto;}
    .mobNav ul{margin: 0;padding: 0;list-style: none;display: -webkit-box;display: -ms-flexbox;display: flex;}
    .mobNav ul li{}
    .mobNav ul li a{width: 60px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 7px;}
    .mobNav ul li a img{width: 22px;display: block;}
    .mobNav ul li.CallMenu a{background:#773d00;}
    .mobNav ul li.HumbMenu a{background:#9c6000;}
    .mobNav ul li a span{display: block;color: #fff;text-transform: uppercase;font-weight: 600;font-size: 11px;margin-top: 5px;}
	#HamburgerMegamenu{position: fixed;opacity: 0;visibility: hidden;pointer-events: none; width: 100%;height: 100%;top:0;z-index: -1;-webkit-transition: all 0.4s ease;-o-transition: all 0.4s ease;transition: all 0.4s ease;padding:0; background: #854800;overflow: hidden; display:block;}
	#HamburgerMegamenu.shadow{opacity: 1;visibility: visible;pointer-events: all;z-index: 9999999;}
	#HamburgerMegamenu.shadow:after{content:'';background-color:white;}
	#HamburgerMegamenu .mm-main-container{max-width:100%;margin:0px auto 0;padding:20px 20px;position: relative;height: 100%;display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
	#HamburgerMegamenu .megamenu-header{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;/* border-bottom:1px solid rgb(255 255 255 / 11%); */padding:3px 0px;position:relative;width: 100%;}
	#HamburgerMegamenu .megamenu-header a{width: 120px;height: auto;}
	#HamburgerMegamenu .megamenu-header a img{display:block;margin: 0;width:auto;height:25px;max-width: inherit;-webkit-filter: brightness(0) invert(1);filter: brightness(0) invert(1);}
	#HamburgerMegamenu .Hamburgermm-close{color:#ffffff;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;font-weight: 400;z-index: 99;background: transparent;border: none;padding: 0;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;cursor: pointer;-webkit-transition: all 1s ease;-o-transition: all 1s ease;transition: all 1s ease;position: absolute;top: 50%;right: 0px;-webkit-transform: translate(0px,-50%);-ms-transform: translate(0px,-50%);transform: translate(0px,-50%);}
	#HamburgerMegamenu .Hamburgermm-close img {width: 20px;padding:0 0 5px 0;border:0px solid black;border-radius: 0%;/* -webkit-filter: brightness(1) invert(1);filter: brightness(1) invert(1); */}
	#HamburgerMegamenu .Hamburgermm-close span {font-size: 11px;line-height: 11px;padding: 0px 0 0 0px;margin: 0px 0 0;display: block;font-family: 'Roboto', sans-serif;font-weight: bold;text-transform: uppercase;letter-spacing:0px;height:auto;-webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;overflow: hidden;}
	#HamburgerMegamenu .Hamburgermm-close:hover span {height:14px;}
	#HamburgerMegamenu .MobMegaMenuSec{display:none;}
	#HamburgerMegamenu .megamenu-container{display: -webkit-box;display: -ms-flexbox;display: flex;margin: 0px auto 0;position:relative;padding:20px 0px 0;width:100%;height:calc(100% - 91px);-webkit-box-flex: 1;-ms-flex: 1;flex: 1;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
	#HamburgerMegamenu .megamenu-container:before {content: "";position: absolute;width: 1px;height: 100%;top:-5px;left:50%;background:#fff;z-index: 9;opacity: 0;pointer-events: none;}
	#HamburgerMegamenu .megamenu-container>div{position:relative;-webkit-box-flex: 0;-ms-flex:0 0 auto;flex:0 0 auto;}
	#HamburgerMegamenu .megamenu-container>div:before{content:"";position:absolute;}
	#HamburgerMegamenu .mmMenuCon{padding-bottom: 20px;border-bottom: 1px solid rgb(255 255 255 / 11%);}
	#HamburgerMegamenu .mmMenuCon ul.HamBSMenu {margin: 0;padding: 0;list-style: none;}
	#HamburgerMegamenu .mmMenuCon ul.HamBSMenu>li{position:relative;}
	#HamburgerMegamenu .mmMenuCon ul.HamBSMenu>li>a{display: block;line-height: 2;color: #fff;text-transform: capitalize;font-size: 17px;border-bottom: 1px solid rgb(255 255 255 / 7%);}
	#HamburgerMegamenu .mmMenuCon ul.HamBSMenu>li>span{display: block;line-height: 2;color: #fff;text-transform: capitalize;font-size: 17px;border-bottom: 1px solid rgb(255 255 255 / 7%);position: relative;}
	#HamburgerMegamenu .mmMenuCon ul.HamBSMenu>li>span:before{content: '';position: absolute;border-left: 5px solid transparent;border-right: 5px solid transparent;border-top: 7px solid #ffffff;right: 15px;top: 50%;margin-top: -5px;opacity: 0.5;}
	#HamburgerMegamenu .mmMenuCon ul.HamBSMenu>li:last-child>a{border-bottom: 0px solid rgb(255 255 255 / 7%);}
	#HamburgerMegamenu .mmMenuCon ul.HamBSMenu li a:hover{text-decoration: underline;}
	#HamburgerMegamenu .mmMenuCon ul.HamBSMenu ul{list-style: none;padding: 15px;}
	#HamburgerMegamenu .mmMenuCon ul.HamBSMenu ul>li>a{display: block;line-height: 2;color: #fff;text-transform: capitalize;font-size: 17px;border-bottom: 1px solid rgb(255 255 255 / 7%);}
	#HamburgerMegamenu .stcol {padding-top:20px;}
	#HamburgerMegamenu .stcol h6{margin: 0 0px 15px;font-size: 20px;line-height: 1;color: #fff;text-transform: uppercase;}
	#HamburgerMegamenu .stcol a.contlink {display: block;color: #fff;font-size: 15px;line-height: 2;padding-left: 40px;margin-bottom: 4px;position: relative;}
	#HamburgerMegamenu .stcol a.contlink:before{content:"";position: absolute;width:27px;height:100%;top:0;left:0;-webkit-filter: brightness(0) invert(1);filter: brightness(0) invert(1);}
	#HamburgerMegamenu .stcol a.contlink.phoneno:before{background:url(../../assets/images/mob-call-icon.svg) no-repeat center center;background-size:25px;}
	#HamburgerMegamenu .stcol a.contlink.emailid:before{background:url(../../assets/images/mob-mail-icon.svg) no-repeat center center;background-size:25px;}
}
@media screen and (max-width:550px) {
    nav.nav{display: none;}
    .headlogo a img {height: 25px;}
    header {padding:0px;background: #ffffff;}
    .headlogo {padding:0px 20px;}
    .HeadContainer{/*-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;*/}
    .mobNav{display: block;margin-left: auto;}
    .mobNav ul{margin: 0;padding: 0;list-style: none;display: -webkit-box;display: -ms-flexbox;display: flex;}
    .mobNav ul li{}
    .mobNav ul li a{width: 60px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 7px;}
    .mobNav ul li a img{width: 22px;display: block;}
    .mobNav ul li.CallMenu{display: none;}
    .mobNav ul li.CallMenu a{background:#773d00;}
    .mobNav ul li.HumbMenu a{background:#9c6000;}
    .mobNav ul li a span{display: block;color: #fff;text-transform: uppercase;font-weight: 600;font-size: 11px;margin-top: 5px;}




}
@media screen and (max-width:420px) {
    .headlogo a img {height: 20px;}
    .mobNav ul li a img {width: 18px;}
    .mobNav ul li a span{font-size: 10px;margin-top: 3px;}
}

