@font-face {
    font-family: 'edwardian_script_itcregular';
    src: url('../fonts/itcedscr-webfont.woff2') format('woff2'),
         url('../fonts/itcedscr-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

body{font-family: 'Poppins', sans-serif;font-size:16px;}
a{text-decoration: none;}
img{width:100%;}

.modal-backdrop{z-index: 9999;}
.modal{z-index: 9999;}

.hBanSlider {overflow: hidden;}
.hBanSlider .slick-list .slick-track>div>div{line-height: 0;}
.hBanItem{position: relative;}
.hBanItemImg{position: relative;}
/*.hBanItemImg:before{content:"";position: absolute;width: 28%;height: 100%;left:13%;top: 0;background: url(../images/HBanBSImg.png) no-repeat center bottom;background-size: 100%;z-index: 9;}
.hBanItemImg:after{content:"Legal Guidance";position: absolute;left:40%;bottom: 0;z-index: 9;line-height: 0.73;color: #ffffff;text-transform: uppercase;font-size: 9vh;font-weight: bold;opacity: 0.11;}*/
.hBanItemImg img{position: relative;display: block;width: 100%;}
.hBanItemCont{position: absolute;width: 100%;height: 100%;top: 0;transform: translate(0, 0%);right: 0;-webkit-transform: translate(0, 0%);-moz-transform: translate(0, 0%);-ms-transform: translate(0, 0%);-o-transform: translate(0, 0%);}
.hBanItemInCont{position:absolute;width:100%;height:fit-content;top:0;left:0;display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;border: 1px solid #7e7e7e;}
.hBanItemCont .container{position: relative;height: 100%;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.hBIBox {}
.hBIBox img{height: 60px;width: auto;margin: 0 0 5px;}
.hBIBox h1{font-size: 4.5vw;color: #ffffff;font-weight: 700;line-height: 1.15;margin-bottom: 5px;}
.hBIBox p{line-height: 1.5;color: #ffffff;margin: 0;}
.hBIBox a.expolink{display: block;position: absolute;bottom: 20px;right:20px;line-height: 1.5;color: #a0a0a0;text-transform: uppercase;letter-spacing: 4px;font-size: 14px;font-weight: 600;}
.hBIBox a.expolink:hover{color:#ffffff;text-decoration: underline;}
.hBanSlider .slick-arrow{width: 40px;height: 55px;opacity: 0;z-index: 99;-webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;}
.hBanSlider .slick-arrow:before{opacity: 0;visibility: hidden;pointer-events: none;}
.hBanSlider:hover .slick-arrow{opacity: 0.4;}
.hBanSlider:hover .slick-arrow:hover{opacity: 0.8;}
.hBanSlider .slick-prev{left:-45px;background: #333 url(../images/arrow-slider-prev.png) no-repeat center center;background-size: 14px;}
.hBanSlider .slick-next{right:-45px;background: #333 url(../images/arrow-slider-next.png) no-repeat center center;background-size: 14px;}
.hBanSlider:hover .slick-prev{left:0;}
.hBanSlider:hover .slick-next{right:0;}

.HIntroSec{position: relative;}
.HIntroImgdiv{position: relative;}
.HIntroImgdiv img{display: block;}
.HIntroContdiv{position: absolute;top: 0%;left: 0%;width: 100%;height: 100%;}
.HIntroContdiv .container{position: relative;height: 100%;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.HIntroContdiv h5{color: #1d1f22;font-size: 16px;letter-spacing: 3px;margin: 0 0 5px;}
.HIntroContdiv h2{color: #a76a01;font-weight: bold;font-size: 90px;line-height: 1.1;margin: 0;letter-spacing: -3px;margin-bottom: 10px;}
.HIntroContdiv p{line-height: 1.8;}
.HIntroContdiv a.expolink{margin-top: 30px;color: #1d1f22;font-size: 15px;letter-spacing: 3px;display: inline-block;background: #fff;line-height: 45px;padding: 0 30px;border-radius: 30px;box-shadow: 0 0 0 2px rgb(167 106 1 / 14%);}
.HIntroContdiv a.expolink:hover{background: #a76a01;color: #fff;}

.ComHeadTitles{position: relative;}
.textalcenter{text-align: center;}
.mrgnbtm{margin-bottom: 50px;}
.ComHeadTitles h5{color: #ffffff;font-weight: 500;font-size:18px;line-height: 1;margin: 0;letter-spacing: 2px;}
.ComHeadTitles h2{color: #ffffff;font-weight: bold;font-size: 80px;line-height: 1;margin: 0;letter-spacing: -0.5px;margin-bottom: 10px;}
.ComHeadTitles p{color: #ffffff;}

.HServiceSec{padding: 100px 0;background:  url(../images/hserviceBgImg.jpg) no-repeat center center;background-size: 100% 100%;margin-top: -1px;position: relative;}
.HServiceSec::before{content: '';position: absolute;width: 50%;padding-top: 40%;bottom: 0;right: 0;background: url(../images/hserviceBgBfrImg.png) no-repeat center bottom;background-size: 100%;    mix-blend-mode: multiply;opacity: 0.5;pointer-events: none;}
.HServiceSec .container{position: relative;}
.ServAcc{border: none;}
.ServAcc .accordion-item{border: none;background: transparent;}
.ServAcc .accordion-item:first-of-type{border-top-left-radius: 0;border-top-right-radius: 0;}
.ServAcc .accordion-item:last-of-type{border-bottom-right-radius:0;border-bottom-left-radius: 0;}
.ServAcc .accordion-header{position: relative;}
.ServAcc .accordion-button{background: transparent;box-shadow: none;padding:0 70px;border-bottom: 1px solid rgb(255 255 255 / 14%);-webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;}
.ServAcc .accordion-button *{-webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;}
.ServAcc .accordion-button .AccIcon{padding: 0;}
.ServAcc .accordion-button .AccTitle{width: 42%;margin: 0 0 0 2%;}
.ServAcc .accordion-button .AccPara{width: 40%;}
.Accconter{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;width: 100%;padding: 30px 0;}
.AccIcon img{width:100%;}
.AccTitle h6{color: #e7e7e7;letter-spacing: 3px;margin-bottom: 5px;}
.AccTitle h3{color: #e7e7e7;margin: 0;font-size: 28px;letter-spacing: -0.5px;font-weight: 600;line-height: 1.1;}
.AccPara p{color: #e7e7e7;margin: 0;line-height: 1.6;}
.ServAcc .accordion-button::after{position: absolute;right:80px;top: 50%;margin-top: -15px;width: 30px;height: 30px;-webkit-transform: rotate(270deg);-ms-transform: rotate(270deg);transform: rotate(270deg);background-position: center center;border: 1px solid #e7e7e7;border-radius: 50%;-webkit-filter: brightness(0) invert(1);filter: brightness(0) invert(1);}
.ServAcc .accordion-button[aria-expanded="true"]{background:  url(../images/AccactiveBg.jpg) no-repeat center top;background-size: 100%;border-bottom: 1px solid rgb(255 255 255 / 0%);}
.ServAcc .accordion-button[aria-expanded="true"] .Accconter{border-bottom: 1px solid rgb(255 255 255 / 14%);padding-top: 70px;}
.ServAcc .accordion-button[aria-expanded="true"] .AccIcon img{-webkit-filter: brightness(0) invert(1);filter: brightness(0) invert(1);}
.ServAcc .accordion-button[aria-expanded="true"] .AccTitle h6{color: #ffffff;}
.ServAcc .accordion-button[aria-expanded="true"] .AccTitle h3{color: #ffffff;}
.ServAcc .accordion-button[aria-expanded="true"] .AccPara p{color: #ffffff;}
.ServAcc .accordion-button[aria-expanded="true"]::after{-webkit-transform: rotate(0deg);-ms-transform: rotate(0deg);transform: rotate(0deg);}
.ServAcc .accordion-body{color: #ffffff;padding:30px 70px 70px;min-height: 380px;}
.ServAcc .accordion-collapse{background:  url(../images/AccactiveBg.jpg) no-repeat center bottom;background-size: 100%;border-bottom: 5px solid #a97933;}
ul.caserul{margin: 0;padding: 0;list-style: none;width: 80%;display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
ul.caserul li{-webkit-box-flex: 1;-ms-flex: 0 0 49%;flex: 0 0 49%;padding: 15px;}
ul.caserul li small{font-size: 22px;font-weight: 600;padding: 5px 0 5px 20px;border-left: 2px solid #ffffff;display: block;line-height: 1.2;}

.HServCont ul.ServAccUl{margin: 0;padding: 0;list-style: none;}
.HServCont ul.ServAccUl li{border-bottom: 1px solid hsla(0,0%,100%,.14);padding: 0 70px;}
.HServCont ul.ServAccUl li:first-child{border-top: 1px solid hsla(0,0%,100%,.14);}
.HServCont ul.ServAccUl li:hover{border-bottom-color:#a76a01;}
.HServCont ul.ServAccUl li .ServAccUlli{-webkit-box-align: center;-ms-flex-align: center;align-items: center;display: -webkit-box;display: -ms-flexbox;display: flex;padding: 30px 0;}
.HServCont ul.ServAccUl .AccIcon{}
.HServCont ul.ServAccUl .AccIcon img{}
.HServCont ul.ServAccUl .AccTitle{margin-left: 20px;margin-right: auto;}
.HServCont ul.ServAccUl .AccTitle h6{}
.HServCont ul.ServAccUl .AccTitle h3{}
.HServCont ul.ServAccUl .exbtn{}
.HServCont ul.ServAccUl .exbtn a.expolink {background: #fff;border: 2px solid #fff;border-radius: 30px;color: #1d1f22;display: block;font-size: 14px;font-weight: 500;letter-spacing: 3px;line-height: 34px;padding: 0 20px;}
.HServCont ul.ServAccUl .exbtn a.expolink:hover{background: #1d1f22;color: #fff;}
.HNewEventSec{padding: 100px 0;}
.HNewEventSec .ComHeadTitles h2{color:#a76a01;text-transform: unset;}
.HNewEventSec .ComHeadTitles p{color:#555555;}
.hneItems{position: relative;width: 85%;}
.hneItemImg{display: block;}
.hneItemImg img{width:100%;display: block;}
.hneItemCont{padding-top: 20px;}
.hneItemCont h3{color:#a76a01;text-transform: capitalize;font-weight: bold;font-size: 48px;line-height: 1;margin: 0;}
.hneItemCont p{margin: 15px 0;}
.hneItemCont a.EMoreLink{color: #1d1f22;font-size: 14px;font-weight: 600;letter-spacing: 3px;display: inline-block;background: #fff;line-height: 45px;padding: 0 30px;border-radius: 30px;box-shadow: 0 0 0 2px rgb(167 106 1 / 14%);}
.hneItemCont a.EMoreLink:hover{background: #a76a01;color: #ffffff;}

.InPageBanSec {position: relative;}
.AboutusPage .InPageBanSec{background: url(../images/aboutBanBgImg.jpg) no-repeat center center;background-size: cover;}
.BanuspeaksPage .InPageBanSec{background: url(../images/aboutBanBgImg.jpg) no-repeat center center;background-size: cover;}

.ContactusPage .InPageBanSec{background: url(../images/ContusBanBgImg.jpg) no-repeat center center;background-size: cover;}
.ServicesPage .InPageBanSec{background: url(../images/ServicesBanBg.jpg) no-repeat center center;background-size: cover;}
.inBanCtCntain{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;min-height: 550px;}
.inBanContTB{position: relative;}
.inBanContTB h1{margin: 0;font-size: 4vw;font-weight: 700;letter-spacing: -1px;color: #fff;text-align: center;}
nav.breadcrumbnav{margin: 0;padding: 0;}
nav.breadcrumbnav ul{padding: 0;margin: 0;list-style: none;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
nav.breadcrumbnav ul li{color: #fff;font-size: 18px;letter-spacing:3px;}
nav.breadcrumbnav ul li.brLink{margin: 0 10px;}
nav.breadcrumbnav ul li a{color: #fff;}
nav.breadcrumbnav ul li a:hover{text-decoration: underline;}

.ComSecrow{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;position: relative;}
.AboutIntroSec{padding: 100px 0 0px;background: url(../images/AboutIntroBgImg.jpg) no-repeat center center;background-size: cover;}
.auintroimg{width: 45%;}
.auintroimg img{width: 100%;display: block;}
.auintrocont{width: 100%;padding-left: 0%;padding-top: 0%;padding-bottom: 25%;}
.AboutIntroSec .ComHeadTitles{width: 100%; text-align: center;}
.AboutIntroSec .ComHeadTitles h2{color: #a76a01;margin: 10px 0;font-size: 57;}
.AboutIntroSec .ComHeadTitles h5{color:#1d1f22;}
.AboutIntroSec .ComHeadTitles p {line-height: 1.8;color: #555555; width: 100%; }

.AboutwhowrSec{position: relative;}
.whowertbbox{position: absolute;width: 100%;height: 100%;top: 0;left:0;z-index: 9;}
.whowertbbox .container{position: relative;height: 100%;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.whowertbbox .ComHeadTitles h5{color: #1d1f22;}
.whowertbbox .ComHeadTitles h3{color: #a76a01;font-size: 4vw;letter-spacing: -3px;line-height: 1;font-weight: 700;}
.whowertbbox .ComHeadTitles p{color:#555555;line-height: 1.8;}

.AboutAwardSec{padding:100px 0;background: url(../images/AwardsSecBgImg.jpg) no-repeat center center;background-size: cover;}
.awardcol {width: 20%;padding:0 10px;}
.awarditem{padding: 0px;width: 90%;display: block;margin-inline: auto;margin-bottom: 15px;}
.teamsboxss{width: 95%;margin-inline: auto;text-align: center;}
.btmspace .teamsboxss{margin-bottom: 45px;}
.teamsboxss h4{color:#ffffff;font-size: 1.35vw;font-weight: 600;letter-spacing: -1px;line-height: 1;margin: 0 0 3px;}
.teamsboxss p.enquirenpara{margin: 0px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.teamsboxss p.enquirenpara a{display: block;color: #fff;font-size: 90%;letter-spacing: 0.5px;font-weight: 500;text-decoration: underline;}

.ContusIntroSec{padding: 100px 0 0;background-color:#ecebea ;position: relative;}
.ContusIntroSec::before{content:"";position: absolute;width: 100%;height: 100px;bottom: -1px;left:0;background: #303030;}
.ContusIntroSec .container{position: relative;}
.Cotfrmcontn{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;background: #fff url(../images/ContfromBgImg.jpg) no-repeat bottom right;background-size: 100%; padding: 40px 40px 80px;}
.ContfLCol{width: 38%;}
.Contfimg{display: block;}
.Contfimg img{display: block;width:100%;}
.ContfRCol{width: 62%;padding: 0 6%;}
.Contfaddrs{background: #9c6000;padding: 35px;}
.Contfaddrs h5{color: #ffffff;letter-spacing: 2px;font-size: 16px;}
.Contfaddrs h2{color: #ffffff;font-size: 58px;font-family: 'edwardian_script_itcregular'; font-weight: 100;margin: 0; line-height: 0.9; margin-bottom: 12px;}
.Contfaddrs p{color: #fff;}
.Contfaddrs p:last-child{margin: 0;}
.Contfrmdiv {}
.Contfrmdiv h5{color: #1d1f22;letter-spacing: 2px;font-size: 16px;}
.Contfrmdiv h3{color: #9d6305;font-size:66px;letter-spacing: -2px;font-weight: 700;margin: 0;}
.Contfrmdiv p{}
.CotfrmRow{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;margin: 0 -15px;}
.ContusFrom{margin:30px 0 45px;}
.formcol {padding: 0 15px;margin-bottom: 30px;}
.LastRow .formcol {margin-bottom: 0px;}
.halfcol{width: 50%;}
.fullcol{width:100%;}
.CusfrmIncotn .form-control{background: #f4f4f4;height: 50px;border-radius: 30px;line-height: 50px;padding: 0 30px;}
.CusfrmIncotn .form-control:focus{border:1px solid #9c6000;box-shadow: none;}
.CusfrmIncotn .form-control::-webkit-input-placeholder {color: #040d2d;}
.CusfrmIncotn .form-control:-ms-input-placeholder {color: #040d2d;}
.CusfrmIncotn .form-control::placeholder {color: #040d2d;font-weight: 500;}
.CusfrmIncotn button.btn-primary{box-shadow: 0 0 0 10px #f4efe5;background: #ab6f07;line-height: 42px;padding: 0 30px;border: 2px solid #ab6f07;border-radius: 30px;letter-spacing: 1px;font-weight: 500;}
.CusfrmIncotn button.btn-primary:hover{background: #ffffff;color: #ab6f07;}
.ContusPESMList{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;padding-top: 30px;border-top: 2px solid #cbced5;}
.ContusPESM {width: 50%;margin-bottom: 45px;}
.ContusPESM.ContSmedia{margin-bottom: 0px;}
.ContusPESM h4 {margin: 0;}
.ContusPESM h4 a{color: #373737;font-weight: 700;letter-spacing: -1px;}
.ContusPESM h4 a:hover{color: #9c6000;}
.ContusPESM ul.smedialink{margin: 0;padding: 0;list-style: none; display: -webkit-box;display: -ms-flexbox;display: flex;}
.ContusPESM ul.smedialink li{margin-right: 10px;}
.ContusPESM ul.smedialink li a{width: 35px;display: block;height: 35px;line-height: 35px;text-align: center;border: 1px solid #a8a8a8;color: #a8a8a8;border-radius: 50%;}
.ContusPESM ul.smedialink li a:hover{color:#9c6000;border-color: #9c6000;}

.ABanubtnfix{position: fixed;bottom: 15px;right: 15px;z-index: 9;}
.ABanubtnfix button{background: transparent;border: transparent;border-radius: 0;padding: 0;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-align: end;-ms-flex-align: end;align-items: flex-end;}
.ABanubtnfix button span{display: block;background: #a76a01;padding: 5px 10px;line-height: 1;font-size: 20px;text-transform: capitalize;font-weight: 600;}
.ABanubtnfix button:hover{background: transparent;border: none;}
.ABanubtnfix button:focus{background: transparent;border-color: transparent;box-shadow: none;}
.ABanubtnfix button:focus-visible{outline: none;}
.ABanubtnfix button:hover span{background:#955e00;}

.SerListSec{padding: 100px 0;background: url(../images/ServicesListBg.jpg) no-repeat top left;background-size: cover;}
.SerListSec .ComHeadTitles h2{color: #a76a01;}
.SerListSec .ComHeadTitles p{color: #555555;}
.SerListrow {display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center; }
.SerListcol{-webkit-box-flex:1;-ms-flex:0 0 50%;flex:0 0 50%;padding: 20px;}
.SerListitem{background: #ffffff;padding: 35px;min-height: 100%;position: relative;box-shadow: 0 0 5px 0px rgb(51 51 51 / 25%);}
.SerListitem img{width: 70px;-webkit-filter: brightness(1) invert(0);filter: brightness(1) invert(0);}
.SerListitem h4{font-weight: 700;letter-spacing: -0.5px;color: #9c6000;margin: 15px 0;line-height: 1;font-size: 30px;}
.SerListitem p{color: #1d1f22;margin: 0;}
.SerListitem a{color: #1d1f22;letter-spacing: 3px;font-weight: 600;position: absolute;bottom: 30px;left: 35px;}
.SerListitem:hover{background: #9c6000;}

.SerListitem:hover a.EMoreLink{background: #a76a01;color: #ffffff;}
.SerListitem:hover img{-webkit-filter: brightness(0) invert(1);filter: brightness(0) invert(1);}
.SerListitem:hover h4{color: #ffffff;}
.SerListitem:hover p{color: #ffffff;}
.SerListitem:hover a{color: #ffffff;}
.SerListitem:hover a:hover{text-decoration: underline;}

.SerinListSec{padding: 100px 0;background: #f9f9f9;}
.SerinListSec .ComHeadTitles h2{color: #a76a01;font-size: 50px;}
.SerinListSec .ComHeadTitles p{color: #555555;line-height: 1.8;}
.SerInListinRow{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.SInLHalfCol{-webkit-box-flex:1;-ms-flex:1 0 50%;flex:1 0 50%;}
.SInLFullCol{-webkit-box-flex:1;-ms-flex:1 0 100%;flex:1 0 100%;}
.SerInListCol img{box-shadow: 0 0 22px rgb(0 0 0 / 25%);-webkit-filter: grayscale(1);filter: grayscale(1);}
.SerInListcol {padding: 80px 0;border-bottom: 2px solid #a56901;}
.SerInListrow .SerInListcol:first-child{padding-top:0;}
.SerInListrow .SerInListcol:last-child{padding-bottom:0;border-bottom: 0px solid #a56901;}
.SerInListCol h4{font-size: 30px;font-weight: 700;letter-spacing: -1px;color: #9c6000;}
.SerInListCol p{color: #1d1f22;line-height: 2;}
.SerInListCont{padding-left: 75px;}
.SerInListMartop{padding-top: 50px;}
.SerInListMartop p{margin: 0;}

.AbtBox img { height: 60px; width: auto; margin: 0 0 5px;}
.AbtBox h3 { font-size: 30px; color: #403f3c; text-transform: capitalize; font-weight: 700; line-height: 1; margin-bottom: 5px;}
.AboutItemInCont { position: absolute;    width: 100%;}
.AbtBox {flex: 0 50%;position: relative;}
.AboutItemInCont {width: 100%;display: flex;flex-wrap: wrap;margin-top: 14%;}
.AbtBox.AtBoxOne {text-align: end;right: 100px;}
.AbtBox.AtBoxTwo {left: 100px;}
.AbtBox.AtBoxThree {text-align: end;right: 290px;top: 118px;}
.AbtBox.AtBoxFour {top: 118px;left: 270px;}
/* section.AboutIntroSec { margin-bottom: -270px; } */
.AboutItemInCont .AbtBox Button.expolink { background: none;border: 0;color: #000;font-size: 14px;font-weight: 600;letter-spacing: 3px;line-height: 1.5;padding: 0;text-decoration: underline;}
.AboutItemInCont .AbtBox Button.expolink:focus{box-shadow: none;}
.AboutItemInCont .AbtBox Button.expolink:hover{text-decoration: none;}

.auintrocont .ComHeadTitles Button.expolink { background: none; border: 0px;padding: 0;line-height: 1.5; color: #000; text-transform: uppercase; letter-spacing: 4px; font-size: 14px; font-weight: 600;text-decoration: underline}
.auintrocont .ComHeadTitles Button.expolink:hover{text-decoration: none;}
.auintrocont .ComHeadTitles Button.expolink:focus{box-shadow: none;}
body.AboutusPage {    overflow-x: hidden;}
body.BanuspeaksPage {    overflow-x: hidden;}
.aboutmePopup .modal-header .btn-close {
    background: #333 url(../images/menuclose-icon.svg) no-repeat center center;
    background-size: 50%;
    border-radius: 0;
    position: absolute;
    top: 1px;
    right: 1px;
    margin: 0;
}
.btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(13, 110, 253, 0.25);
    opacity: 1;
}

.AboutPopTitles.aos-init.aos-animate {text-align: center;}
.AboutPopSec .AboutPopTitles h5 {text-transform: uppercase;font-weight: 500;font-size: 15px;line-height: 1; margin: 0; letter-spacing: 5px;color: #1d1f22;}
.AboutPopSec .AboutPopTitles h2 {color: #a76a01; text-transform: uppercase; font-weight: bold; font-size: 40px; line-height: 1; margin: 10px 0;}
.AboutPopSec .AboutPopTitles p {line-height: 1.8; color: #555555;width: 100%; margin: 0 auto;}
.AboutPopauthorTitles.aos-init.aos-animate h2 { color: #a76a01; font-size: 20px; font-weight: 600; line-height: 1; margin: 10px 0; text-transform: uppercase;}
.aboutmePopup .modal-header {border: 0;}
.aboutmePopup .modal-body {padding: 0px 30px 50px 252px;}
.AboutPopauthorTitles.aos-init.aos-animate h2{color: #a76a01 !important; text-transform: capitalize; font-weight: 700; font-size: 24px; line-height: 1; margin: 10px 0;}
ul.aboutboxpopup li{position: relative;list-style: none;}
ul.aboutboxpopup li:before {content: ''; position: absolute; left: -16px; top: 4px; width: 0; height: 0;border-top: 7px solid transparent;border-left: 9px solid #a76a01;border-bottom: 7px solid transparent;}
.aboutlegalPopup.legalPopup .modal-body{ margin-bottom: 30px;}
.aboutlegalPopup .modal-body{ height: 430px;overflow-y: scroll; overflow-x: hidden;}
.aboutlegalPopup .modal-body::-webkit-scrollbar{width: 5px;	background-color: #F5F5F5;}
.aboutmePopup.aboutlegalPopup .modal-body::-webkit-scrollbar-thumb{	border-radius: 10px;-webkit-box-shadow: inset 0 0 6px #a76a01;background-color: #e1a949;}
.auintroimg.mobile { display: none;}
.AbtPopUp .modal-content { position: relative;display: flex; flex-direction: column; width: 100%; pointer-events: auto; border: 1px solid rgba(0, 0, 0, 0.2);  border-radius: 0.3rem;outline: 0; background: #0a0a0a url(../images/HBanBSImg.png)no-repeat bottom left; background-size: contain; color: #bbb; }
.aboutmePopup .modal-body p, .aboutmePopup .modal-body ul li { font-size: 14px;}
.aboutmePopup .modal-body h2{ color: #a76a01;}

.TeamMemberPopUp .modal-content{position: relative;display: flex;flex-direction: column; width: 100%; pointer-events: auto; border: 1px solid rgba(0, 0, 0, 0.2);border-radius: 0.3rem;outline: 0; background: #0a0a0a;color: #bbb;overflow: hidden;}
.aboutTeamPopup .modal-body{padding: 0;}
.aboutTeamPopup .modal-header .btn-close {background: #333 url(../images/menuclose-icon.svg) no-repeat center center;background-size: 50%;border-radius: 0;position: absolute;top: 1px;right: 1px;margin: 0;z-index: 9;border: 2px solid #ffffff;}
.aboutTeamPopup .modal-header .btn-close:hover{color: #a76a01;border-color:#a76a01;}
.aboutTeamPopup .modal-header{padding: 0;height: 0px;border: none;}
.aboutTeamPopup .TeamMemberPopSec{padding: 50px;}
.aboutTeamPopup .TeamMemberTitles h5 {text-transform: uppercase;font-weight: 500;font-size: 15px;line-height: 1; margin: 0; letter-spacing: 5px;color: #1d1f22;}
.aboutTeamPopup .TeamMemberTitles h2 {color: #ffffff;font-weight: bold; font-size: 40px; line-height: 1; margin: 0 0 10px;}
.aboutTeamPopup .TeamMemberTitles p {line-height: 1.8; color: #bbbbbb;width: 100%; margin: 0 auto;}
.aboutTeamPopup .TeamMemberTitles ul{margin: 0;padding: 0;list-style: none;}
.aboutTeamPopup .TeamMemberTitles ul.ulOverflow{height: 35vh;overflow-y: scroll;padding-right: 15px;}
.aboutTeamPopup .TeamMemberTitles ul.ulOverflow::-webkit-scrollbar{width: 5px;	background-color: #F5F5F5;}
.aboutTeamPopup .TeamMemberTitles ul.ulOverflow::-webkit-scrollbar-thumb{	border-radius: 10px;-webkit-box-shadow: inset 0 0 6px #a76a01;background-color: #e1a949;}
.aboutTeamPopup .TeamMemberTitles ul li{position: relative;margin-bottom: 5px;padding-left: 20px;}
.aboutTeamPopup .TeamMemberTitles ul li:before {content: '';position: absolute;left: 0px;top: 4px;width: 0;height: 0;border-top: 7px solid transparent;border-left: 9px solid #a76a01;border-bottom: 7px solid transparent;}
ul.caserul li p{ padding: 0px 0 0px 20px;}
.HServiceSec li.exbtn {display: flex; align-items: center; justify-content: center;}
.HServiceSec li.exbtn a.expolink { display: block;background: #ffffff; color: #1d1f22;font-weight: 600; line-height: 34px; padding: 0 20px; border-radius: 30px;border: 2px solid #fff; font-size: 14px; text-transform: uppercase;letter-spacing: 3px;}
.HServiceSec li.exbtn a.expolink:hover {background: #1d1f22;color: #ffffff;}
.SerListSec .SerListitem a{position: initial;}
.SerListSec .SerListitem p {margin-bottom: 12px;}
.SerListSec .ComHeadTitles h2 {font-size: 50px;line-height: 30px; margin-bottom: 10px;}
.SerListSec .ComHeadTitles h2 small {font-size: 20px; color: #000; text-transform: capitalize;}
.AboutIntroSec .ComHeadTitles h2 {font-size: 50px;line-height: 30px; margin-bottom: 20px;}
.AboutIntroSec .ComHeadTitles h2 small {font-size: 20px; color: #000; text-transform: capitalize;}
.fade.videopopup.modal.show .modal-header {border-bottom: 0px;}
p.field-error {color: red;}
.ABanubtnfix.ABanubtnfixwp {bottom: 80px;}
.ABanubtnfix.ABanubtnfixwp ul{list-style: none;}
.SpeaksIntroSec{padding: 100px 0;background: url(../images/ServicesListBg.jpg) no-repeat top left;background-size: cover;}
.SpeaksIntroSec .ComHeadTitles h2{color: #a76a01;font-size: 50px;}
.SpeaksIntroSec .ComHeadTitles p{color: #555555;}
.BSintrocont {text-align: center;}
.BSintrocont .ComHeadTitles h5 { color: #1d1f22;}
.Speaksec{padding-top: 50px;}
.SpeaksIntroSec .hneItemCont h3{font-size: 20px;line-height: 26px;}
.Speaksec .row{padding: 20px 0;}
.speakvid{position: relative;cursor: pointer;}


.speakvid:before{content: '';position: absolute;background: url(../images/y-icon.png)no-repeat;width: 80px;height: 69px;background-size: 100%;top: 50%;left: 50%; transform: translate(-50%, -50%);}
.Speaksec .row .col { display: flex; justify-content: center; }

.SpeaksIntroSec .hneItems {width:84%;}
.hneItemImg.speakvid:hover {
    box-shadow: 0 0 10px rgb(0 0 0);
}

.ThankuPage .InPageBanSec{background: url(../images/Thankyou.jpg) no-repeat center center;background-size: cover;}



body.ThankuPage .EnquireNSec{display: none;}


.thankuintro{padding: 100px 0;}
.thankuintro h3 {font-weight: 600; font-size: 37px; line-height: 48px; color: #303030;margin-bottom: 40px;}
.thankuintro h4 {font-weight: 600; font-size: 27px;margin-bottom: 40px;}




 a.gotohome {color: #1d1f22; font-size: 14px; font-weight: 600;text-transform: uppercase;letter-spacing: 5px; display: inline-block; background: #fff; line-height: 45px; padding: 0 30px;
    border-radius: 30px; box-shadow: 0 0 0 2px rgb(167 106 1 / 14%);
}
a.gotohome:hover {background: #a76a01; color: #ffffff;}


html { scroll-behavior: initial !important; }


.SerListcol a.EMoreLink{color: #1d1f22;font-size: 14px; margin: 10px 0; font-weight: 600;letter-spacing: 3px;display: inline-block;background: #fff;line-height: 45px;padding: 0 30px;border-radius: 30px;box-shadow: 0 0 0 2px rgb(167 106 1 / 14%);}


.CallMenu.mobile{display: none;}


.dessclimPopup .modal-header { border: 0;}
.AdesclimerTitles h2 {text-align: center;}
.buttons {
    text-align: center;
}
button#acceptBtn {
    background: none;
    border: 2px solid #f5f5f5;
    border-radius: 50px;
    padding: 6px 24px;
    background:#a76a01 ;
    color: #fff;
}
button#acceptBtn:hover{
    color:#a76a01 ;
    background: #fff;
    border: 2px solid #a76a01;

}

.videopopup {}
.videopopup .modal-content{border:none;position: relative;border-radius: 0;}
.videopopup .modal-body{padding: 0;line-height: 0;}
.videopopup .modal-header{padding: 0;}
.videopopup .modal-header button.btn-close{position: absolute;top: 0;right: 0;z-index: 99;border: 2px solid #000;border-radius: 50%;background-color: #fff;margin: 0;-webkit-transform: translate(50%, -50%);-ms-transform: translate(50%, -50%);transform: translate(50%, -50%);opacity: 0.4;}
.videopopup .modal-header button.btn-close:hover{opacity: 0.8;}

@media screen and (max-width:1820px) {
    .hBIBox img{height: 55px;}
    .hBIBox h3{font-size: 35px;}
    .ComHeadTitles h2{font-size: 74px;}
    .ServAcc .accordion-button .AccIcon {width: 80px;}
    .AccTitle h6{font-size: 15px;}
    .AccTitle h3{font-size: 26px;}
    ul.caserul li small{font-size: 20px;}
    .hneItemCont h3{font-size: 45px;}
    .inBanCtCntain{min-height: 520px;}
    .inBanContTB h1{font-size: 90px;}
    .AboutIntroSec {padding: 70px 0 0px;}


}
@media screen and (max-width:1700px) {
    body {font-size: 15px;}
    .HIntroContdiv h2{font-size: 80px;}
    .HIntroContdiv a.expolink {font-size: 14px;letter-spacing: 3px;line-height: 40px;}
    .ComHeadTitles h2 {font-size: 70px;}
    .AccTitle h6 {font-size: 14px;}
    .AccTitle h3 {font-size: 24px;}
    .ServAcc .accordion-button .AccIcon {width: 70px;}
    .ServAcc .accordion-button[aria-expanded="true"] .Accconter{padding-top: 55px;}
    .ServAcc .accordion-body{padding: 30px 70px 55px;}
    ul.caserul li small {font-size: 19px;}
    .hneItemCont h3 {font-size: 38px;}
    .hneItemCont a.EMoreLink{font-size: 13px;letter-spacing: 3px;line-height: 40px;}
    .inBanCtCntain {min-height: 490px;}
    .inBanContTB h1 {font-size: 82px;}
    .auintroimg {width: 36%;}
    .Contfrmdiv h3{font-size: 60px;}
    .HServCont ul.ServAccUl .AccIcon {width: 75px;}
    .HServCont ul.ServAccUl li .ServAccUlli{padding: 25px 0;}
    .AboutItemInCont { margin-top: 18%; }
    .hBIBox {}
    .hBIBox h1{line-height: 1.1;}

}
@media screen and (max-width:1610px) {
    
    .HServiceSec {padding: 90px 0;}    
    .HNewEventSec {padding: 90px 0;}
    .hBIBox{}
    .hBIBox img {height: 48px;}
    .hBIBox h3 {font-size: 30px;}
    .hBIBox a.expolink{font-size: 13px;}
    .HIntroContdiv h5{font-size: 14px;}
    .HIntroContdiv h2 {font-size: 70px;}
    .ComHeadTitles h2 {font-size: 65px;}
    .ComHeadTitles h5{font-size: 16px;}
    .ServAcc .accordion-button .AccIcon {width: 60px;}
    .mrgnbtm {margin-bottom: 35px;}
    .AccTitle h6 {font-size: 13px;}
    .AccTitle h3 {font-size: 22px;}
    .AccPara p{font-size: 14px;}
    .Accconter{padding: 25px 0;}
    .ServAcc .accordion-button[aria-expanded="true"] .Accconter {padding-top: 50px;}
    .ServAcc .accordion-body {padding: 30px 70px 50px;}
    ul.caserul li small {font-size: 18px;}
    ul.caserul { width: 90%; }    
    .hneItemCont h3 {font-size: 34px;}
    .hneItemCont a.EMoreLink {letter-spacing: 2px;line-height: 36px;}
    .inBanCtCntain {min-height: 460px;}
    .inBanContTB h1 {font-size: 76px;}
    nav.breadcrumbnav ul li{font-size: 16px;}
    .Contfrmdiv h5{font-size: 15px;}
    .Contfrmdiv h3 {font-size: 56px;line-height: 1;}
    .ContusFrom {margin: 25px 0 40px;}
    .Cotfrmcontn{width: 90%;margin: 0 auto;padding: 30px 30px 60px;}
    .CusfrmIncotn .form-control{height: 45px;font-size: 15px;}
    .CusfrmIncotn button.btn-primary{line-height: 38px;font-size: 14px;}
    .Contfaddrs h5{font-size: 15px;}
    .Contfaddrs h2{font-size: 32px;}
    .ContusPESM {margin-bottom: 38px;}
    .ContusPESM h4 {font-size: 22px;}
    .HServCont ul.ServAccUl .AccIcon {width: 65px;}
    .HServCont ul.ServAccUl .exbtn a.expolink{font-size: 13px;letter-spacing: 2px;}
    .SpeaksIntroSec .ComHeadTitles h2 {font-size: 45px;}

}
@media screen and (min-width:1401px) and (max-width:1500px) {
    .container {max-width: 1100px;}
}
@media screen and (max-width:1500px) {
    body {font-size: 14px;}
    .HServiceSec {padding: 80px 0;}
    .HNewEventSec {padding: 80px 0;}
    .hBIBox {}
    .hBIBox h3 {font-size: 28px;}
    .hBIBox img {height: 45px;}
    .hBIBox a.expolink {font-size: 12px;}
    .HIntroContdiv h2 {font-size: 65px;}
    .ComHeadTitles h2 {font-size: 58px;}
    .ComHeadTitles h5{font-size: 15px;}
    .AccTitle h6 {font-size: 12px;}
    .AccTitle h3 {font-size: 20px;}
    .ServAcc .accordion-button .AccTitle {width: 38%;}
    .ServAcc .accordion-button .AccPara {width: 45%;}
    .ServAcc .accordion-button[aria-expanded="true"] .Accconter {padding-top: 40px;}
    .ServAcc .accordion-body {padding: 30px 70px 40px;min-height: 340px;}
    ul.caserul li small {font-size: 16px;}
    .HIntroContdiv{}
    .hneItemCont h3 {font-size: 32px;}
    .hneItemCont p {margin: 10px 0;}
    .hneItemCont a.EMoreLink {letter-spacing: 1px;line-height: 36px;font-size: 12px;margin-top: 15px;padding: 0 25px;}
    .HIntroContdiv a.expolink {font-size: 12px;letter-spacing: 1px;line-height: 36px;padding: 0 25px;margin-top: 15px;}
    .inBanCtCntain {min-height: 410px;}
    nav.breadcrumbnav ul li{font-size: 15px;}
    .inBanContTB h1 {font-size: 70px;}
    .AboutIntroSec .ComHeadTitles p {line-height: 1.6;}
    .Contfrmdiv h3 {font-size: 52px;}
    .Contfrmdiv p br{display: none;}
    .CusfrmIncotn .form-control {height: 42px;font-size: 14px;}
    .formcol{margin-bottom: 25px;}
    .ContusFrom {margin: 25px 0 30px;}
    .CusfrmIncotn button.btn-primary {line-height: 32px;font-size: 13px;padding: 0 25px;box-shadow: 0 0 0 5px #f4efe5;}
    .ContusPESMList{padding-top: 25px;}
    .Contfrmdiv h5 {font-size: 14px;}
    .ContusPESM h4 {font-size: 21px;}
    .ContusPESM {margin-bottom: 30px;}
    .ContusPESM ul.smedialink li a{width: 30px;height: 30px;line-height: 30px;font-size: 12px;}
    .Contfaddrs {padding: 30px;}
    .Contfaddrs h5 {font-size: 14px;}
    .Contfaddrs h2 {font-size: 28px;}
    .ContusIntroSec {padding: 80px 0 0;}
    .AbtBox.AtBoxThree { right: 235px;top: 64px; }
    .AbtBox.AtBoxFour { top: 64px; left: 255px; }
    .SerListitem h4 { font-size: 27px;   }
    .HServiceSec li.exbtn a.expolink {letter-spacing: 1px;line-height: 36px;font-size: 12px;margin-top: 15px;padding: 0 25px; }
    a.gotohome{letter-spacing: 1px;line-height: 36px;margin-top: 15px;padding: 0 25px;}
    .thankuintro h3 {font-size: 30px;line-height: 39px;    margin-bottom: 19px;}
    .thankuintro h4 {font-size: 21px;margin-bottom: 20px; }
    .SerListSec .ComHeadTitles h2 { font-size: 35px; }
    .SerinListSec .ComHeadTitles h2 {font-size: 40px;}
    .SerListcol a.EMoreLink {font-size: 12px;line-height: 35px;padding: 0 22px;}
    .AboutIntroSec .ComHeadTitles h2 {        font-size: 35px;        line-height: 33px;    }
    .HServCont ul.ServAccUl .AccIcon {width: 65px;}
    .HServCont ul.ServAccUl li .ServAccUlli{padding: 20px 0;}
    .HServCont ul.ServAccUl .exbtn a.expolink{font-size: 12px;letter-spacing: 1.5px;line-height: 30px;padding: 0 15px;}
    .aboutTeamPopup .TeamMemberTitles ul li{    padding-left: 12px;}
    .aboutTeamPopup .TeamMemberTitles ul li:before{border-top: 5px solid transparent;border-left: 6px solid #a76a01;border-bottom: 5px solid transparent;}


}
@media screen and (min-width:1201px) and (max-width:1400px) {
    .container {max-width: 1000px;}
}
@media screen and (max-width:1400px) {
    body {font-size: 13.5px;}
    .HServiceSec {padding: 70px 0;}
    .HNewEventSec {padding: 70px 0;}
    .hBIBox h3 {font-size: 24px;}
    .hBIBox p{font-size: 13px;margin: 0;}
    .hBIBox a.expolink{bottom: 15px;right: 15px;}
    .hBIBox {}
    .HIntroContdiv h5 {font-size: 13px;}
    .HIntroContdiv h2 {font-size: 60px;}
    .ComHeadTitles h2 {font-size: 52px;}
    .ServAcc .accordion-button .AccIcon {width: 46px;}
    .AccTitle h3 {font-size: 18px;}
    .AccPara p {font-size: 13px;}
    .Accconter {padding: 20px 0;}
    .ServAcc .accordion-button{padding: 0 60px;}
    .ServAcc .accordion-button[aria-expanded="true"] .Accconter {padding-top: 35px;}
    .ServAcc .accordion-body {padding: 20px 60px 40px;min-height: 320px;}
    .hneItemCont h3 {font-size: 28px;}
    .hneItemCont a.EMoreLink{padding: 0 22px;line-height: 32px;}
    .HIntroContdiv a.expolink{line-height: 32px;padding: 0 22px;}
    .EnquireNSec .textBlock p.enquirenpara a.enquirenLink{line-height: 32px;}
    .inBanCtCntain {min-height: 390px;}
    nav.breadcrumbnav ul li {font-size: 14px;}
    .inBanContTB h1 {font-size: 63px;}
    .AboutIntroSec {padding: 50px 0 0px;}
    .AboutIntroSec .ComHeadTitles {width: 100%;}
    .awardcol.coltwo {padding-top: 80px;}
    .awardcol.colfour {padding-top: 40px;}
    .Cotfrmcontn {width: 100%;}
    .Contfrmdiv h5 {font-size: 13px;}
    .Contfrmdiv h3 {font-size: 46px;}
    .CusfrmIncotn .form-control {height: 42px;font-size: 13px;}
    .Contfaddrs h5 {font-size: 13px;}
    .ContusPESMList {padding-top: 20px;}
    .formcol {margin-bottom: 20px;}
    .Contfaddrs {padding: 25px;}
    /* section.AboutIntroSec { margin-bottom: -200px; } */
    .AboutAwardSec { padding: 80px 0; }
    .AboutItemInCont { margin-top: 19%; }
    .auintrocont { padding-bottom: 29%; }
    /* .SerListSec .ComHeadTitles h2 { font-size: 38px; } */
    .SerListSec .ComHeadTitles h2 small {font-size: 18px;}
    .AboutIntroSec .ComHeadTitles h2 small {font-size: 18px;}    
    .HServiceSec li.exbtn a.expolink { line-height: 32px; padding: 0 22px; }
    a.gotohome {line-height: 36px;padding: 0px 26px;  }
    .SerinListSec .ComHeadTitles h2 { font-size: 35px; }
    .AboutIntroSec .ComHeadTitles h2 {font-size: 30px;line-height: 33px;}
    .SerListSec .ComHeadTitles h2 {font-size: 30px;}
    .SerInListCol h4 {font-size: 22px;}
    .SerInListCont {padding-left: 50px;}
    .SpeaksIntroSec .ComHeadTitles h2 {font-size: 40px;}    
    .HServCont ul.ServAccUl .AccIcon {width: 55px;}
    .HServCont ul.ServAccUl li .ServAccUlli{padding: 15px 0;}
    .EnquireNSec .ENTbright .Enquiretbbox h2,.hBIBox h1{font-size: 4vw;}
    .aboutTeamPopup .TeamMemberTitles h2{font-size: 30px;}
    .aboutTeamPopup .TeamMemberPopSec {padding: 40px;}
}
@media screen and (max-width:1300px){
    .inBanCtCntain {min-height: 370px;}
    .AbtBox p br { display: none; }
    .AbtBox { flex: 0 0 35%;}
    .AboutItemInCont { justify-content: center; }  
}
@media screen and (max-width:1200px){
    .hBIBox p br { display: none; }
    .hBIBox img { height: 38px; }
    .hBIBox h3 { font-size: 19px; }
    .hBIBox p { font-size: 12px; }
    .EnquireNSec .ENTbright { }
    .EnquireNSec .ENTbleft { left: 13%; }
    .headlogo a img { height: 30px;}
    .AbtBox { flex: 0 0 34%; }
    .AbtBox.AtBoxFour { left: 203px; }
    .AbtBox.AtBoxThree { right: 203px;}
    .auintrocont { padding-bottom: 33%; }
    .AbtBox.AtBoxOne {right: 60px;}
    .AbtBox.AtBoxTwo {left: 60px;}
    .thankuintro h3 {font-size: 26px;line-height: 33px;    }
    .thankuintro h4 {font-size: 20px; }
    .Speaksec { padding-top: 20px; }
    .SerinListSec .ComHeadTitles h2 { font-size: 29px; }
    .SerInListCol h4 {font-size: 26px;}
    .EnquireNSec .textBlock p.absimg img {height: 66px;}
    .EnquireNSec .textBlock.ENTbright p.absimg {margin-left: -9%;}
    .EnquireNSec .ENTbleft {left: 17%;}
    .SpeaksIntroSec .ComHeadTitles h2 {font-size: 33px;}
    .HIntroContdiv h2 {font-size: 48px;}
    .HIntroContdiv p {line-height: 1.5;}
    .HIntroContdiv a.expolink{margin-top: 10px;}
    .teamsboxss h4{font-size: 1.5vw;letter-spacing: 0px;}
    .awarditem {padding: 0px 0px;}
    .Cotfrmcontn{padding: 30px 30px 30px;}
    .hBanInCont{width: 40%;}
    .EnquireNSec .ENTbright .Enquiretbbox h2{margin-bottom: 15px;}
    .aboutTeamPopup .TeamMemberTitles h2 {font-size: 24px;}
}
@media screen and (max-width:1100px){
    body {font-size: 13px;}
    .HServiceSec {padding: 60px 0;}
    .HNewEventSec {padding: 60px 0;}
    .hBanItemImg:after{font-size: 4vh;}
    .hBanItemCont{}
    .hBIBox img {height: 40px;}
    .hBIBox {}
    .hBIBox h3 {font-size: 22px;}
    .HIntroContdiv {}
    .HIntroContdiv p {margin: 0;}
    .HIntroContdiv h2 {font-size: 50px;}
    .ComHeadTitles h2 {font-size: 42px;}
    .hneItemCont a.EMoreLink{margin-top: 5px;}
    .inBanCtCntain {min-height: 300px;}
    nav.breadcrumbnav ul li {font-size: 13px;}
    .inBanContTB h1 {font-size: 56px;}
    .AboutAwardSec {padding:50px 0;}
    .ComHeadTitles h5 {font-size: 13px;letter-spacing: 3px;}
    .SerListitem h4 { font-size: 23px; }
    .EnquireNSec .ENTbright {  }
    .EnquireNSec .ENTbleft { left: 14%; }
    .AbtBox p{width: 90%;}
    .AbtBox.AtBoxOne p { margin-left: 30px; }
    .AbtBox.AtBoxThree p { margin-left: 28px; }
    .AbtBox.AtBox p { margin-left: 47px; }
    .AbtBox.AtBoxFour { left: 196px; }
    .AbtBox.AtBoxThree { right: 191px; }
    .teamsboxss h4 {font-size: 2vw;letter-spacing: 0px;}
}
@media screen and (max-width:1030px) {
    .headlogo a img {height: 25px;}
}
@media screen and (max-width:1000px){
    ul.caserul { width: 100%; }
    .ServAcc .accordion-body { padding: 20px 20px 40px; }
    .ServAcc .accordion-collapse { background-size: cover; }
    .AccPara p br { display: none; }
    .ServAcc .accordion-button::after { right: 45px; }
    .AccTitle h3 br { display: none; }
    .ComHeadTitles p br { display: none; }
    .ServAcc .accordion-button { padding: 0 20px; }
    .ServAcc .accordion-button .AccTitle { padding-right: 10px; }
    .EnquireNSec .ENTbright {  }
    .AbtBox.AtBoxFour {left: 138px;}   
    .AbtBox p { width: 100%; }
    .AbtBox.AtBoxOne p { margin-left: 0; }
    .AbtBox.AtBoxThree p { margin-left: 0; }
    /* section.AboutIntroSec { margin-bottom: -155px; } */
    .auintrocont { padding-bottom: 46%;}
    .AbtBox { flex: 0 0 47%;}
    .AbtBox.AtBoxThree { right: 149px;}
    .AboutAwardSec { padding:50px 0; }
    .AboutItemInCont { margin-top: 40%;}
    .AbtBox img { height: 50px; }
    .AbtBox h3 {font-size: 22px;}
    .thankuintro h3 {font-size: 22px;line-height: 29px; }
    .thankuintro h4 {font-size: 17px; margin-bottom: 14px; }
    .btmspace .teamsboxss {margin-bottom: 25px;}

}
@media screen and (max-width:900px){
    .SerInListCont { padding-left: 30px; }
    .SerInListMartop { padding-top: 10px; }
    .SerInListCol h4 { font-size: 25px; }
    .SerInListcol { padding: 60px 0; }

    .AbtBox.AtBoxFour {flex: 0 36%;}
    .AbtBox.AtBoxThree {flex: 0 36%; }
    .AbtBox {flex: 0 0 38%;}
    .SpeaksIntroSec .hneItemCont h3 { font-size: 16px; line-height: 21px; }
    .Speaksec { padding: 0 0;    }
}
@media screen and (max-width:800px){
    
    .hBanItemImg img{height: 30vh;object-fit: cover;}
    .hBanItemImg:before{width: 42%;height: 100%;left: 0%;}
    .hBanItemCont{}
    .hBIBox{}
    .hBIBox:last-child{}
    .hBanItemInCont:before,.hBanItemInCont:after{opacity: 0;visibility: hidden;pointer-events: none;}
    .hBIBox img {height: 35px;}
    .hBIBox h3 {font-size: 18px;}
    .hBIBox p br{display: none;}
    .HIntroImgdiv {position: relative;width: 100%;overflow: hidden;}
    .HIntroImgdiv img { display: block;width: 120%;margin-left: 0%;}    
    .HIntroContdiv h2 {font-size: 30px;letter-spacing: -0.5px;}
    .HIntroContdiv h2 br{display: none;}
    .HIntroContdiv {}
    .HIntroContdiv p{line-height: 1.5;}
    .HIntroContdiv p br{display: none;}
    .ComHeadTitles p br{display: none;}
    .ServAcc .accordion-button {padding: 0 20px;}
    .AccTitle h6 {font-size: 11px;}
    .AccTitle h3 {font-size: 20px;letter-spacing: 0;}
    .AccPara p br{display: none;}
    .Accconter {padding: 15px 0;}
    .ServAcc .accordion-button[aria-expanded="true"] .Accconter {padding-top: 15px;}
    .ServAcc .accordion-button::after{right: 15px;}
    .ServAcc .accordion-body {padding: 20px;min-height: auto;}
    ul.caserul li {padding: 10px;}
    ul.caserul {width: 100%;}
    ul.caserul li small {font-size: 14px;}
    .hneItemCont h3 {font-size: 24px;}
    .hneItemCont {padding-top: 12px;}
    .inBanCtCntain {min-height: 225px;}
    .InPageBanSec{margin-top: 50px;}
    .inBanContTB h1 {font-size: 48px;}
    .auintrocont{padding-top: 0;padding-left: 3%;}
    .AboutIntroSec .ComHeadTitles h2 {margin: 7px 0;}
    .awardcol {padding: 0 5px;}
    .awarditem {padding: 10px 5px;}
    .awardcol.coltwo {padding-top: 60px;}
    .awardcol.colfour {padding-top: 30px;}
    .Cotfrmcontn{padding: 20px 20px 40px;}
    .Contfaddrs {padding: 15px;}
    .Contfrmdiv h3 {font-size: 36px;}
    .Contfaddrs h2 {font-size: 24px;}
    .Contfaddrs h5 {font-size: 12px;}
    .CusfrmIncotn .form-control {height: 38px;font-size: 12px;padding: 0 20px;}
    .formcol {margin-bottom: 15px;}
    .ContusFrom {margin: 20px 0 20px;}
    .Contfrmdiv h5 {font-size: 12px;letter-spacing: 1px;}
    .ContusPESM h4 {font-size: 17px;}
    .ContusPESM {margin-bottom: 20px;}

    .EnquireNSec .ENTbright {}

    .auintrocont { padding-bottom: 0; }
    .AboutItemInCont {position: relative; }
    .AboutItemInCont { margin-top: 0; }
    .AbtBox { flex: 0 0 100%; padding-bottom: 22px;}
    .AbtBox.AtBoxFour, .AbtBox.AtBoxThree { flex: 0 0 100%; }
    .AbtBox.AtBoxOne, .AbtBox.AtBoxThree  { text-align: revert;top: 0px;  right: 0px; }
    .AbtBox.AtBoxTwo, .AbtBox.AtBoxFour {top:0px; left: 0px; }
    section.AboutIntroSec {margin-bottom: 0; }
    .auintroimg.mobile { display: block; }
    .auintroimg.desktop{display: none;}
    .AboutIntroSec .ComHeadTitles p {width: 90%;}
    .auintrocont .ComHeadTitles Button.expolink { margin-top: 11px; }
    .aboutmePopup .modal-body { padding: 0px 12px 0px 12px; }
    .AbtPopUp .modal-content { background-blend-mode: soft-light;  }
    .SerListcol { flex: 0 0 100%; }
    .speakvid:before { width: 66px;height: 55px; }
    .thankuintro h3 {font-size: 20px;line-height: 27px;}
    .thankuintro h3 br {display: none;}
    .SpeaksIntroSec .ComHeadTitles h2 {font-size: 29px;}
    .CallMenu.mobile{display: block;}
    .CallMenu.desktop{display: none;}
    .whowertbbox .whowertb{width: 50%;}
    .whowertbbox .whowertb p{line-height: 1.5;}
    .auintroimg {width: 100%;order: 1;}
    .awardcol {width: 33.333%;}
    .teamsboxss h4 {font-size: 22px;}
    .teamsboxss p.enquirenpara a{font-size: 100%;}
    .Enquiretbbox{width: 50%;}
    .EnquireNSec .ENTbright .Enquiretbbox h2{-webkit-text-stroke: 10px rgb(123 75 0 / 35%);}
    .EnquireNSec .ENTbright .Enquiretbbox h2, .hBIBox h1 {font-size: 34px;}
    .hBanItemCont .container{-webkit-box-align: end;-ms-flex-align: end;align-items: flex-end;padding-bottom: 4vh;}
    .HServCont ul.ServAccUl li {padding: 0 0px;}
}
@media screen and (max-width:550px){  
    .AboutusCont{overflow: hidden;}
    body {font-size: 14px;}  
    .hBanItemImg{background: url(../images/hbanMobBgImg.jpg) no-repeat center center;background-size: cover;}
    .hBanItemImg img{height: 85vh;object-fit: cover;opacity: 0;}
    .hBanItemImg:before{width: 90%;height: 100%;left: 5%;}
    .hBanItemCont{}
    .hBanInCont {width: 100%;}
    .hBanItemInCont{width: 97%;top: auto;bottom: 0%;left: 1.5%;}
    .hBanItemImg:after{width:100%;left: 0;text-align: center;opacity: 0;visibility: hidden;pointer-events: none;}
    .hBIBox{}
    .hBIBox img {width: 45px;height: auto;margin: 0;position: absolute;left: 10px;top: 50%;-webkit-transform: translate(0px, -50%);-ms-transform: translate(0px, -50%);transform: translate(0px, -50%);}
    .hBanItemCont .container {-webkit-box-align: start;-ms-flex-align: start;align-items: flex-start;padding-bottom: 0;padding-top: 100px;}
    .hBIBox a.expolink {bottom: 0;right: 0;position: relative;}
    .HIntroSec {padding: 40px 25px 130%;background:#fafafa url(../images/HIntroMobImg.jpg) no-repeat center bottom;background-size: 101%;}
    .HIntroImgdiv {display: none;}
    .HIntroContdiv {left: 0;width: 100%;position: relative;-webkit-transform: translate(0 , 0%);-ms-transform: translate(0 , 0%);transform: translate(0 , 0%);}
    .HServiceSec {padding: 40px 10px;}
    .ServAcc .accordion-button::after{width: 25px;height: 25px;background-size: 50%;}
    .ServAcc .accordion-button .AccPara {display: none;}
    .ServAcc .accordion-button .AccTitle {width: 85%;}
    .ServAcc .accordion-button[aria-expanded="true"] {background: url(../images/AccactiveBg.jpg) no-repeat center top;background-size: 160%;}
    .ServAcc .accordion-collapse {background: url(../images/AccactiveBg.jpg) no-repeat right bottom;background-size: cover;}
    .Accconter {padding: 12px 0;}
    .ServAcc .accordion-button[aria-expanded="true"] .Accconter {padding-top: 12px;}
    ul.caserul li {-webkit-box-flex: 1;-ms-flex: 1 0 50%;flex: 1 0 50%;}
    .HNewEventSec {padding: 40px 10px;}
    .HNewEventSec .col {-webkit-box-flex: 1;-ms-flex: 1 0 100%;flex: 1 0 100%;}
    .hneItems {width: 100%;margin-bottom: 35px;}
    .HNewEventSec .row .col:last-child .hneItems{margin-bottom: 0px;}
    .inBanCtCntain {min-height: 160px;}
    .AboutusPage .InPageBanSec{background-size: cover;}
    .BanuspeaksPage .InPageBanSec{background-size: cover;}

    .ContactusPage .InPageBanSec{background-size: cover;}
    nav.breadcrumbnav ul li {font-size: 12px;}
    .inBanContTB h1 {font-size: 34px;letter-spacing: 0;}
    .AboutIntroSec {padding: 30px 0 0;}
    .auintrocont {padding-top: 0;padding-left: 0;width: 100%;text-align: center;-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
    .auintroimg.mobile{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;margin-inline: -15px;width: calc(100% + 30px);background: rgb(242, 242, 242);background: linear-gradient(0deg, rgba(242, 242, 242, 1) 0%, rgb(255 255 255 / 0%) 100%);}
    .AboutItemInCont {-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;padding-top: 30px;}
    .AboutIntroSec .ComHeadTitles {width: 100%;margin-bottom: 20px;}
    .auintroimg {width: 100%;-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
    .AboutAwardSec {padding: 40px 0;}
    .awardcol {width: 50%;}
    .awardcol.colthree {margin-top: -60px;}
    .awardcol.colfour {padding-top: 0;}
    .ContusIntroSec {padding: 40px 0 0;}
    .Cotfrmcontn {padding: 0px 0px 20px;}
    .ContfLCol {width: 100%;}
    .ContfRCol {width: 100%;padding: 20px;}
    .halfcol {width: 100%;}
    .ContusPESM {margin-bottom: 20px;width: 100%;}
    .SerInListCont { padding-left: 0px;}
    .SerInListinRow { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }
    .SerinListSec { padding: 45px 0; }
    .SerInListCol.SInLHalfCol img {margin-bottom: 25px;}
    .ServicesPage .InPageBanSec { background-size: cover; }
    .ComHeadTitles h2 { font-size: 36px; }
    .ServAcc .accordion-button .AccTitle {width: 80%; }
    .ENSBgImg{display: none;}
    .EnquireNSec .ENTbright {position: relative;right: 0;height: auto;background: url(../images/footEnowMobBgImg.jpg) no-repeat center bottom;background-size: 100%;padding-block: 40px 85%;}    
    .Enquiretbbox {width: 100%;}
    .EnquireNSec .ENTbright .Enquiretbbox h2{letter-spacing: 0;}
    .AboutPopSec .AboutPopTitles h2 { font-size: 35px; }
    ul.caserul li {flex: 0 0 100%;}
    ul.caserul li p {padding: 0px 0 0px 13px; }
    .Speaksec .row .col {flex: 1 0 100%;}
    .Speaksec .row{padding: 0;}
    .Speaksec {padding-top: 30px;}
    .SpeaksIntroSec {padding: 49px 0;}
    .thankuintro { padding: 37px 0; }
    .SpeaksIntroSec .ComHeadTitles h2 {color: #a76a01;}
    .SpeaksIntroSec .ComHeadTitles h2 {font-size: 24px;line-height: 28px;}
    .EnquireNSec .textBlock.ENTbright p.absimg {margin-left: 0%;}
    .EnquireNSec .textBlock p.absimg img {height: 35px;}
    .SpeaksIntroSec .ComHeadTitles h2 br {display: none;}
    .HServCont ul.ServAccUl li {padding: 0 10px;}
    .HServCont ul.ServAccUl li .ServAccUlli{position: relative; padding-left: 65px;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-align: start;-ms-flex-align: start;align-items: flex-start;}
    .HServCont ul.ServAccUl li .ServAccUlli::before{content: '';position: absolute;width: 55px;height: 100%;left: 0;top: 0;}
    .HServCont ul.ServAccUl li:nth-child(1) .ServAccUlli::before{background: url(../images/expertise-icon01.png) no-repeat center center;background-size: 100%;}
    .HServCont ul.ServAccUl li:nth-child(2) .ServAccUlli::before{background: url(../images/expertise-icon02.png) no-repeat center center;background-size: 100%;}
    .HServCont ul.ServAccUl li:nth-child(3) .ServAccUlli::before{background: url(../images/expertise-icon03.png) no-repeat center center;background-size: 100%;}
    .HServCont ul.ServAccUl li:nth-child(4) .ServAccUlli::before{background: url(../images/expertise-icon04.png) no-repeat center center;background-size: 100%;}
    .HServCont ul.ServAccUl li:nth-child(5) .ServAccUlli::before{background: url(../images/expertise-icon05.png) no-repeat center center;background-size: 100%;}
    .HServCont ul.ServAccUl li:nth-child(6) .ServAccUlli::before{background: url(../images/expertise-icon06.png) no-repeat center center;background-size: 100%;}
    .HServCont ul.ServAccUl .AccTitle {margin-left: 0;}
    .HServCont ul.ServAccUl .exbtn {margin-top: 8px;}
    .HServCont ul.ServAccUl .AccIcon {display: none;}
    .whowrSBgImg{display: none;}
    .whowertbbox {position: relative;width: 100%;height: auto;background:#ecf0f3 url(../images/AboutIntroMobImg.jpg) no-repeat center bottom;background-size: 100%;padding-block: 40px 70%;}
    .whowertbbox .whowertb {width: 100%;}
    .whowertbbox .ComHeadTitles h3{font-size: 34px;letter-spacing: 0;}
    .aboutTeamPopup .TeamMemberPopSec {padding: 40px 20px;}
    .teamsboxss h4 {font-size: 18px;}
    .btmspace .teamsboxss {margin-bottom: 0;}
    .ComSecrow .teamsboxss{margin-bottom: 30px;}
}
@media screen and (max-width:450px){
    .hBanItemImg img { height: 85vh; }
    .EnquireNSec .ENTbright { }
    .SerListitem p br { display: none; }
    .SerListitem h4 { font-size: 20px; margin: 8px 0; }
    .SerListSec { padding: 44px 0; }
    .ComHeadTitles h2 { font-size: 29px; }
    .SerinListSec .ComHeadTitles h2 {font-size: 30px; }

    .SerinListSec .ComHeadTitles h2 br {
        display: none;
    }

    .hBIBox { }
}
@media screen and (max-width:400px){
    .hBIBox img {width: 40px;}
    .hBIBox h3 {font-size: 16px;margin-bottom: 2px;}
    .hBIBox a.expolink {font-size: 11px;}
    .hBanItemCont{}
    .HIntroContdiv h5 {font-size: 12px;}
    .HIntroContdiv h2 {font-size: 35px;}
    .ComHeadTitles h2 {font-size: 34px;}
    ul.caserul li small {font-size: 13px;}
    .AccTitle h3 {font-size: 14px;letter-spacing: 0;font-weight: 500;}
    .ServAcc .accordion-button {padding: 0 8px;}
    .ServAcc .accordion-button::after {right: 9px;}
    .hneItemCont h3 {font-size: 20px;}
    .hneItemCont a.EMoreLink{font-size: 11px;}
    .HIntroContdiv a.expolink {font-size: 11px;}
    ul.caserul li {padding: 10px 10px 10px 0px;}
    ul.caserul li small{padding: 5px 0 5px 10px;font-weight: 500;}
    .ServAcc .accordion-body {padding: 20px 12px;}

    .SerListitem h4 br {
        display: none;
    }
    .SerInListcol {
        padding-bottom: 18px;
    }
    .SerListSec .ComHeadTitles h2 {
        font-size: 28px;
    }
    
    .AboutIntroSec .ComHeadTitles h2 {font-size: 28px;}

    .SerListSec .ComHeadTitles h2 small {
        line-height: 0 !important;
    }
    .AboutIntroSec .ComHeadTitles h2 small {line-height: 0 !important;}


    .SpeaksIntroSec { padding-bottom: 0; }

    .thankuintro h3 {font-size: 18px;line-height: 23px;margin-bottom: 11px; }
    .thankuintro h4 {font-size: 16px; }

    .SerinListSec .ComHeadTitles h2 { font-size: 26px; }
    .SerListSec .ComHeadTitles h2 small {font-size: 16px;}
    .AboutIntroSec .ComHeadTitles h2 small {font-size: 16px;}
    .AboutIntroSec .ComHeadTitles h2 {
        font-size: 24px;
        line-height: 27px;
    }

}
@media screen and (max-width:370px){
    .ABanubtnfix button span { font-size: 17px; }
}