.disclaimer-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    padding: 0 20%;

  }
  .AdesclimerTitles {
    text-align: left;
  }
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
  
  button {
    background: none;
    border: 2px solid #f5f5f5;
    border-radius: 50px;
    padding: 6px 24px;
    background:#a76a01 ;
    color: #fff;
    text-align: center !important;
}
.disclaimer-popup button:hover{
    color:#a76a01 ;
    background: #fff;
    border: 2px solid #a76a01;

}

.AdesclimerTitles h2 {
  text-align: center;
  color: #a76a01;
}
body.HomePage.popup-open {
  overflow-y: hidden;
}



.popup-content .popup-body{ height: 600px;overflow-y: scroll; overflow-x: hidden;padding: 0 20px;}
.popup-content .popup-body::-webkit-scrollbar{width: 4px;	background-color: #F5F5F5;}

.popup-content .popup-body::-webkit-scrollbar-thumb{	border-radius: 10px;-webkit-box-shadow: inset 0 0 2px #a76a01;background-color: #e1a949;}



@media screen and (max-width:880px) {
  .disclaimer-popup { padding: 0 4%;}
  .disclaimer-popup ul {padding-left: 1rem;}
}

@media screen and (max-width:550px) {
  .popup-content .popup-body {height: 470px;}

}