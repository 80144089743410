.EnquireNSec{position: relative;}
/*.EnquireNSec:after{content:"";position:absolute;padding-right: 31%;height:100%;left:21%;top:0;background:url(../../assets/images/fbanusekarImg.png) no-repeat center bottom;background-size:100%;z-index: 9;}*/
.ENSBgImg{position: relative;}
.ENSBgImg img{display: block;}
.EnquireNSec .textBlock{position: absolute;z-index: 9;}
.EnquireNSec .textBlock p.absimg{display: block;}
.EnquireNSec .textBlock p.absimg img{height:145px;width: auto;}
.EnquireNSec .textBlock p{margin: 0;color: #ffffff;}
.EnquireNSec .textBlock p.enquirenpara{display: -webkit-box;display: -ms-flexbox;display: flex;margin-top: 30px;}
.EnquireNSec .textBlock p.enquirenpara a.enquirenLink{display: block;background: #ffffff;color: #1d1f22;font-weight: 600;line-height: 40px;padding: 0 20px;border-radius: 30px;border: 2px solid #fff;font-size: 14px;font-weight: 500;letter-spacing: 2px;}
.EnquireNSec .textBlock p.enquirenpara a.enquirenLink:hover{background: #1d1f22;color: #ffffff;}
.EnquireNSec .ENTbright{right:0;top: 0;width: 100%;height: 100%; z-index: 99;}
.EnquireNSec .ENTbright .container{position: relative;height: 100%;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;}
.EnquireNSec .ENTbright .Enquiretbbox h2{color: #ffffff;font-size: 4.5vw;letter-spacing: -2px;font-weight: 700;line-height: 1.2;margin-bottom: 30px;-webkit-text-stroke: 19px rgb(123 75 0 / 35%);
paint-order: stroke fill;} 
.EnquireNSec .ENTbleft{top: 20%;left: 15%;}
.EnquireNSec .textBlock.ENTbright p.absimg{margin-left: -15%;margin-bottom: 15px;}

.enqnowPopup{box-shadow: none;}
.enqnowPopup .modal-header .btn-close{    background: #333 url(../../assets/images/menuclose-icon.svg) no-repeat center center;background-size: 50%;border-radius: 0;position: absolute;top: 1px;right: 1px;margin: 0;}
.enqnowPopup .modal-content{border: none;border-radius: 0;padding: 40px;}
.enqnowPopup .modal-header{border: none;padding: 0;}
.enqnowPopup .modal-header .modal-title{font-weight: 600;margin-bottom: 10px;}
.enqnowPopup .modal-body{padding: 0;}
.enqnowPopup button.btn{display: block;background: #a76a01;color: #ffffff;line-height: 40px;padding: 0 20px;border-radius: 30px;border: 2px solid #a76a01;font-size: 14px;font-weight: 500;letter-spacing: 2px;}
.enqnowPopup button.btn:hover{background: #ffffff;color: #a76a01;}
.enqnowPopup .form-control{border-radius: 0;}
.enqnowPopup .form-control:focus{border-color: #a76a01;box-shadow: none;}

.MfooterSec{background: #303030;padding: 80px 0;}
.MfooterSec .row {padding: 0;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.MfooterSec .row .col.mfsCol-One{flex: 0 0 35%;}
.MfooterSec .row .col.mfsCol-Two{flex: 0 0 19%;}
.MfooterSec .row .col.mfsCol-Three{flex: 0 0 19%;}
.MfooterSec .row .col.mfsCol-Four{flex: 0 0 27%;}
.MfooterSec .textBlock h4 {margin: 0 0 15px;color: #d5d5d5;font-size: 19px;}
.MfooterSec .textBlock h5 {margin: 0 0 15px;color: #d5d5d5;font-size: 19px;}
.MfooterSec .textBlock p{color:#a8a8a8;font-size:14px;}
.MfooterSec .textBlock p.fcright{margin: 0;margin-bottom: 30px;}
.MfooterSec .textBlock ul {margin:0;padding:0;list-style:none;}
.MfooterSec .textBlock ul li{font-size:14px;color: #a8a8a8!important;}
ul.fBSmMenu li a{color: #a8a8a8;font-size: 14px;display: block;line-height: 26px;}
ul.fBSmMenu li a:hover{color: #ffffff;}
.MfooterSec .textBlock ul.smedialink{display: -webkit-box;display: -ms-flexbox;display: flex;}
.MfooterSec .textBlock ul.smedialink li{margin-right: 10px;}
.MfooterSec .textBlock ul.smedialink li a{width: 35px;display: block;height: 35px;line-height: 35px;text-align: center;border: 1px solid #a8a8a8;color: #a8a8a8;border-radius: 50%;}
.MfooterSec .textBlock ul.smedialink li a:hover{color:#ffffff;border-color: #ffffff;}
.MfooterSec .beopenCol{margin-top: 20px;}
.MfooterSec .beopenCol p a {padding: 3px 30px 3px 0px;display: inline-block;margin: 0 0 0 0px;position: relative;color: #a8a8a8;}
.MfooterSec .beopenCol p a:hover{color: #ffffff;}
/* .MfooterSec .beopenCol p a:before {content: "";position: absolute;width: 22px;height: 22px;top: 50%;right: 0;background: url(../../assets/images/banu-dd-logo.png) no-repeat left center;background-size: 100%;-webkit-transform: translate(0px,-50%);-ms-transform: translate(0px,-50%);transform: translate(0px,-50%);border-radius: 50%;border: 1px solid rgb(255 255 255 / 36%);} */
.MfooterSec .beopenCol p a:before {content: "";position: absolute;width: 90px;height: 35px;top: 50%;right: 0;background: url(../../assets/images/banu-dd-logo.png) no-repeat left center;background-size: 100%;transform: translate(74%,-49%);}
.ContactusPage .EnquireNSec{display: none;}

.MfooterSec .row .col.mfsCol-four { flex: 0 0 20%;}


 
@media screen and (max-width:1820px){
    .EnquireNSec .ENTbright{right:0%;}
    .EnquireNSec .textBlock p.absimg img {height: 130px;}
}
@media screen and (max-width:1700px){
    .EnquireNSec .textBlock p.absimg img {height: 110px;}
    .EnquireNSec .textBlock.ENTbright p.absimg {margin-left: -20%;}
    .EnquireNSec .ENTbright {}
    .EnquireNSec .ENTbleft {top: 23%;left: 16%;}
}
@media screen and (max-width:1610px){
    .EnquireNSec .textBlock p.enquirenpara a.enquirenLink{font-size: 13px;letter-spacing: 2px;line-height: 35px;}
    .MfooterSec {padding: 60px 0;}
    .EnquireNSec .textBlock p.absimg img {height: 104px;}
    .enqnowPopup .form-control{font-size: 15px;}
}
@media screen and (max-width:1500px) {
    .EnquireNSec .textBlock p.absimg img {height: 95px;}
    .EnquireNSec .textBlock p.enquirenpara a.enquirenLink {font-size: 12px;letter-spacing: 1px;line-height: 35px;}
    .MfooterSec {padding: 50px 0;}
    .MfooterSec .textBlock h4,.MfooterSec .textBlock h5{font-size: 17px;margin: 0 0 12px;}
    .MfooterSec .textBlock p {font-size: 13px;}
    ul.fBSmMenu li a{font-size: 13px;}
    .MfooterSec .textBlock ul.smedialink li a{width: 31px;height: 31px;line-height: 31px;}
}
@media screen and (max-width:1400px) {
    .EnquireNSec .textBlock p.absimg img {height: 80px;}
    .EnquireNSec .ENTbright {}
    .EnquireNSec .ENTbleft {top: 25%;left: 18%;}
    .MfooterSec .textBlock h4,.MfooterSec .textBlock h5{font-size: 16px;margin: 0 0 10px;}
    .enqnowPopup .form-control{font-size: 14px;}
}
@media screen and (max-width:1300px) {
    .EnquireNSec .textBlock p.absimg img {height: 75px;}
    .MfooterSec .textBlock p {font-size: 12px;}
    ul.fBSmMenu li a{font-size: 12px;}
}
@media screen and (max-width:1100px) {
    .EnquireNSec .ENTbright {}
    .EnquireNSec .ENTbleft {top: 26%;left: 17%;}
    .EnquireNSec .textBlock p.absimg img {height: 60px;}
    .EnquireNSec .textBlock p.enquirenpara {margin-top: 15px;}
}

@media screen and (max-width:1000px) {
    .ENSBgImg img{height: 30vh;}

}

@media screen and (max-width:800px){
    .EnquireNSec .textBlock p.absimg img {height: 48px;}
    .EnquireNSec .textBlock.ENTbright p.absimg {margin-left: 0;margin-bottom: 8px;}
    .EnquireNSec .ENTbright{}
    .EnquireNSec:after{left: 18%;}
    .EnquireNSec .ENTbleft {top: 17%;left: 12%;}
    .MfooterSec {padding: 40px 0;}
    .MfooterSec .row .col.mfsCol-Two,.MfooterSec .row .col.mfsCol-Three {display: none;}
    .MfooterSec .row .col.mfsCol-One {-webkit-box-flex: 0;-ms-flex: 0 0 60%;flex: 0 0 60%;}
    .MfooterSec .row .col.mfsCol-four{-webkit-box-flex: 0;-ms-flex: 0 0 40%;flex: 0 0 40%;}
    .enqnowPopup .form-control{font-size: 13px;}
}

@media screen and (max-width:550px){
    .EnquireNSec .ENTbleft {display: none;}
    .ENSBgImg img {display: block;height: 70vh;object-fit: cover;}
    .EnquireNSec .textBlock p.absimg{position: relative;padding-top: 15%;}
    .EnquireNSec .textBlock p.absimg::before{content: "";position: absolute;width: 35%;height: 50%;left: 0;top: 0;background: url(../../assets/images/askbs-img2.png) no-repeat left center;background-size: auto 100%;}
    .EnquireNSec .textBlock p.absimg img {height: 50px;}
    .EnquireNSec .ENTbright {}
    .EnquireNSec:after {left: 5%;padding-right: 90%;}
    .MfooterSec .row .col.mfsCol-One {display: none;}
    .MfooterSec .row .col.mfsCol-four {-webkit-box-flex: 0;-ms-flex: 0 0 100%;flex: 0 0 100%;text-align: center;}
    .MfooterSec .textBlock ul.smedialink{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
    .MfooterSec .textBlock ul.smedialink li:last-child{margin-right: 0;}
    .MfooterSec .fcrighttb{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
    .MfooterSec .textBlock p.fcright{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;margin-top: 15px;padding-top: 15px;border-top: 1px solid rgb(255 255 255 / 10%);margin-bottom: 0;}
    .MfooterSec .beopenCol {margin-top: 5px;}
    .MfooterSec {padding: 20px 0 65px;}




}

@media screen and (max-width:420px){
    .EnquireNSec .textBlock p br{display: none;}
    .EnquireNSec .textBlock p.absimg img {height: 34px;}
    .EnquireNSec .textBlock p.absimg {padding-top: 10%;}
    .ENSBgImg img {
        height: 74vh;
    }
    .MfooterSec .textBlock h4, .MfooterSec .textBlock h5 {font-size: 15px;margin: 0 0 5px;}
    .MfooterSec .textBlock p.fcright{margin-top: 10px;padding-top: 10px;}
    .MfooterSec .textBlock p {margin: 0;}
    .MfooterSec .beopenCol {}
    .EnquireNSec .textBlock p.enquirenpara a.enquirenLink {font-size: 11px;}
    .enqnowPopup .modal-content{padding: 30px;}


}